var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { "no-body": "" } },
    [
      _c(
        "div",
        { staticClass: "m-2" },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "7", md: "6" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-end",
                  },
                  [
                    _c("b-form-input", {
                      staticClass: "d-inline-block",
                      attrs: { placeholder: "Pesquise por título ou ID" },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "b-col",
                { staticClass: "offset-md-4", attrs: { cols: "5", md: "2" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        block: "",
                        to: { name: "product-create" },
                      },
                    },
                    [_vm._v(" Novo ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-table", {
        staticClass: "position-relative",
        attrs: {
          "sticky-header": "",
          items: _vm.items,
          responsive: "",
          fields: _vm.fields,
          "primary-key": "id",
          "show-empty": "",
          "empty-text": "No matching records found",
        },
        scopedSlots: _vm._u([
          {
            key: "cell(total)",
            fn: function (data) {
              return [_vm._v(" " + _vm._s(data.item.amount) + " ")]
            },
          },
          {
            key: "cell(action)",
            fn: function (data) {
              return [
                _c("feather-icon", {
                  staticClass: "cursor-pointer cursor",
                  attrs: {
                    id: "product-" + data.item.id + "-preview-icon",
                    icon: "EyeIcon",
                    size: "16",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({
                        name: "order-preview",
                        params: { uuid: data.item.id },
                      })
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "mx-2 mb-2 mt-2 paginate-area" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center justify-content-sm-start",
                  attrs: { cols: "12", sm: "6" },
                },
                [
                  _c("span", { staticClass: "text-muted" }, [
                    _vm._v(" Exibindo 1 de 10 com total de 100 resultados "),
                  ]),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center justify-content-sm-end",
                  attrs: { cols: "12", sm: "6" },
                },
                [
                  _c("b-pagination", {
                    staticClass: "mb-0 mt-1 mt-sm-0",
                    attrs: {
                      "total-rows": _vm.rows,
                      "first-number": "",
                      "last-number": "",
                      "prev-class": "prev-item",
                      "next-class": "next-item",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "prev-text",
                        fn: function () {
                          return [
                            _c("feather-icon", {
                              attrs: { icon: "ChevronLeftIcon", size: "18" },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "next-text",
                        fn: function () {
                          return [
                            _c("feather-icon", {
                              attrs: { icon: "ChevronRightIcon", size: "18" },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.currentPage,
                      callback: function ($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }